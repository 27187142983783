import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { Container } from 'react-bootstrap';
import Text from '../../../components/ui/Text';
import { Col, Row } from '../../../components/ui/Wrapper';
import {
  FooterBottom,
  FooterTop,
  FooterWrap,
  Logo,
  InfoWithIcon,
  WhatsappIcon,
  AddressIcon,
  MessagesIcon,
  FooterWidgetList,
  FooterWidget,
} from './styles';
import Anchor from '../../../components/ui/Anchor';
import Heading from '../../../components/ui/Heading';
import logo from '../../../assets/images/logo/logo-footer.png';
import FollowSocialMedia from '../../../components/ui/FollowSocialMedia/FollowSocialMedia';
import useSiteLocation from '../../../hooks/useSiteLocation';

// eslint-disable-next-line react/prop-types
const FooterHeading = ({ children }) => (
  <Heading color="secondary" as="h5" mb="20px">
    {children}
  </Heading>
);

// eslint-disable-next-line react/prop-types
const FooterAnchorItem = ({ children, path }) => (
  <Anchor path={path} color="white" hover={{ color: 'secondary' }}>
    {children}
  </Anchor>
);

const Footer = ({ copyrightStyle, ...props }) => {
  const siteInfo = useStaticQuery(graphql`
    query FooterSiteQuery {
      site {
        siteMetadata {
          social {
            whatsapp
          }
          contact {
            whatsapp
            address
            consultingInfo
          }
          copyright
        }
      }
    }
  `);

  const {
    copyright,
    social: { whatsapp },
    contact: { address, secondaryAddress, whatsapp: whatsappNumber, consultingInfo },
  } = siteInfo.site.siteMetadata;

  const { rootPath } = useSiteLocation();

  return (
    <FooterWrap>
      {!props.onlyBottom ? (
        <FooterTop>
          <Container>
            <Row>
              <Col lg={6}>
                <Logo>
                  <img src={logo} alt="Logo" />
                </Logo>
                <div>
                  {address && (
                    <Text mb="10px">
                      <InfoWithIcon>
                        <AddressIcon />
                        {address}
                      </InfoWithIcon>
                    </Text>
                  )}
                  {secondaryAddress && (
                    <Text mb="10px">
                      <InfoWithIcon>
                        <AddressIcon />
                        {secondaryAddress}
                      </InfoWithIcon>
                    </Text>
                  )}
                  {whatsappNumber && (
                    <Text mb="10px">
                      <Anchor path={whatsapp} fontWeight="800" color="white" hover={{ color: 'secondary' }}>
                        <InfoWithIcon>
                          <WhatsappIcon />
                          {whatsappNumber}
                        </InfoWithIcon>
                      </Anchor>
                    </Text>
                  )}
                  {consultingInfo && (
                    <Text>
                      <InfoWithIcon>
                        <MessagesIcon />
                        {consultingInfo}
                      </InfoWithIcon>
                    </Text>
                  )}
                </div>
              </Col>
              <Col lg={2} md={4} sm={6}>
                <FooterWidget responsive={{ medium: { mb: '31px' } }}>
                  <FooterHeading>Concursos Militares</FooterHeading>
                  <FooterWidgetList>
                    <li>
                      <FooterAnchorItem path={`${rootPath}/ensino-fundamental-6-ano/cmc-colegio-militar-curitiba`}>
                        CMC
                      </FooterAnchorItem>
                    </li>
                    <li>
                      <FooterAnchorItem path={`${rootPath}/pre-militar/espcex-escola-preparatoria-cadetes-exercito`}>
                        EsPCEx
                      </FooterAnchorItem>
                    </li>
                    <li>
                      <FooterAnchorItem path={`${rootPath}/pre-militar/esa-escola-sargentos-armas-exercito`}>
                        ESSA
                      </FooterAnchorItem>
                    </li>
                    <li>
                      <FooterAnchorItem path={`${rootPath}/pre-militar/eear-escola-sargentos-especialista-aeronautica`}>
                        EEAR
                      </FooterAnchorItem>
                    </li>
                    <li>
                      <FooterAnchorItem path={`${rootPath}/pre-militar/afa-academia-forca-aerea`}>AFA</FooterAnchorItem>
                    </li>
                  </FooterWidgetList>
                </FooterWidget>
              </Col>
              <Col lg={2} md={4} sm={6}>
                <FooterWidget responsive={{ medium: { mb: '27px' } }}>
                  <FooterHeading>Escolas Técnicas</FooterHeading>
                  <FooterWidgetList>
                    <li>
                      <FooterAnchorItem path={`${rootPath}/ensino-medio-9-ano/ifpr-escolas-tecnicas`}>
                        IFPR
                      </FooterAnchorItem>
                    </li>
                    <li>
                      <FooterAnchorItem path={`${rootPath}/ensino-medio-9-ano/ufpr-escolas-tecnicas`}>
                        UFPR
                      </FooterAnchorItem>
                    </li>
                  </FooterWidgetList>
                </FooterWidget>
              </Col>
              <Col lg={2} md={4} sm={6}>
                <FooterWidget>
                  <FooterHeading>PMPR</FooterHeading>
                  <FooterWidgetList>
                    <li>
                      <FooterAnchorItem path={`${rootPath}/pre-militar/pmpr-soldado-policia-militar-parana`}>
                        Soldado PMPR
                      </FooterAnchorItem>
                    </li>
                    <li>
                      <FooterAnchorItem path={`${rootPath}/pre-militar/cfo-curso-formacao-oficiais`}>
                        CFO
                      </FooterAnchorItem>
                    </li>
                    <li>
                      <FooterAnchorItem path={`${rootPath}/ensino-fundamental-6-ano/cpm-colegio-policia-militar`}>
                        CPM 6º Ano
                      </FooterAnchorItem>
                    </li>
                    <li>
                      <FooterAnchorItem path={`${rootPath}/ensino-fundamental-6-ano/cpm-colegio-policia-militar`}>
                        CPM 9º Ano
                      </FooterAnchorItem>
                    </li>
                  </FooterWidgetList>
                </FooterWidget>
              </Col>
            </Row>
          </Container>
        </FooterTop>
      ) : null}
      <FooterBottom>
        <Container>
          <Row className="align-items-center">
            <Col md={6} className="text-center text-md-left">
              {copyright && <Text {...copyrightStyle}>&copy; {`${new Date().getFullYear()} ${copyright}`}</Text>}
            </Col>
            <Col md={6} className="text-center text-md-right">
              <FollowSocialMedia />
            </Col>
          </Row>
        </Container>
      </FooterBottom>
    </FooterWrap>
  );
};

Footer.propTypes = {
  copyrightStyle: PropTypes.shape({ responsive: PropTypes.object }),
  onlyBottom: PropTypes.bool,
};

Footer.defaultProps = {
  copyrightStyle: {
    responsive: {
      small: {
        pb: '15px',
      },
    },
  },
  onlyBottom: false,
};

export default Footer;
